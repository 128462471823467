import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('settings/realestate', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`settings/realestate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings/realestate', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`settings/realestate/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`settings/realestate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/settings/realestate/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`settings/realestate/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/settings/realestate/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    fetchefamilymemberdetails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/familymembers/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchestate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/state/getdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}